<template>
  <div class="container" style="padding-top: 1em">
    <div>
      <img
        :src="require(`@/assets/img/${$omwConfig.display.companyLogo}`)"
        :style="headerImageStyleObject"
      />
      <p
        class="is-size-3 has-text-centered"
        style="padding-top: 1em; padding-bottom: 0em"
      >
        {{
          $t(
            "We're really sorry, but it's not possible to view that appointment",
          )
        }}
      </p>
      <div class="has-text-centered columns is-centered">
        <marketing
          v-if="marketingEnabled"
          class="column is-half"
          style="padding-top: 3em"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Marketing from '@/components/optional/Marketing';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    Marketing,
  },
  computed: {
    headerImageStyleObject() {
      return {
        // height: this.$omwConfig.display.imageHeight,
        width: this.$omwConfig.display.imageWidth,
      };
    },
    marketingEnabled() {
      return this.$omwConfig?.marketing?.enabled;
    },
  },
});
</script>

<style scoped>
h1 {
  font-size: 2rem;
  text-align: center;
  display: block;
  padding: 3em;
  font-weight: 500;
}
img {
  display: block;
  margin: auto auto;
  max-width: 50vw;
}
</style>
